import Logo from './asserts/logo.svg';
import Close from './asserts/close-icon.svg';
// import Wave from './asserts/wave.svg';
import Linkedin from './asserts/linkedin.svg';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import './App.css';
import Form from './form';
import { useState } from 'react';
import Circle0 from './asserts/circle-0.png';
import Circle1 from './asserts/circle-1.png';
import Circle2 from './asserts/circle-2.png';
import Circle3 from './asserts/circle-3.png';
import Circle4 from './asserts/circle-4.png';
import Circle5 from './asserts/circle-5.png';
import LottieControl from './lottie';

AOS.init({
  duration: 1400,
});

function App() {
  const [show, setShow] = useState(false);
  
  return (
    <div className="App">
      <header className='header'>
        <div className='container'>
          <div className='header-logo'>
            <img src={Logo} alt="Logo" style={{width:"130px"}}/>
            <p>A Sigma Labs company</p>
          </div>
           {/* <a href='https://www.linkedin.com/company/sigmahqx/'target="_blank" rel="noreferrer" >
             <img src={Linkedin} alt="Linkedin"style={{width:"35px"}} />
           </a> */}
        </div>
      </header>

      <main>
        <div className='container'>
          <div className='content'>
            <h1>Research & Development wing specialized in pioneering advanced cryptographic DeFi protocols and algorithms</h1>
            <button className='btn-white' onClick={() => setShow(!show)}>Contact Us</button>
          </div>
        </div>
      </main>

      <div className='circles'>
        <img src={Circle0} data-aos-delay="50" data-aos="zoom-in" className='circle circle-0' alt="icon circle" />
        <img src={Circle1} data-aos-delay="150" data-aos="zoom-in" className='circle circle-1' alt="icon circle" />
        <img src={Circle2} data-aos-delay="250" data-aos="zoom-in" className='circle circle-2' alt="icon circle" />
        <img src={Circle3} data-aos-delay="350" data-aos="zoom-in" className='circle circle-3' alt="icon circle" />
        <img src={Circle4} data-aos-delay="450" data-aos="zoom-in" className='circle circle-4' alt="icon circle" />
        <img src={Circle5} data-aos-delay="550" data-aos="zoom-in" className='circle circle-5' alt="icon circle" />
      </div>

      {/* <img src={Wave} className='wave' alt="Wave" /> */}
      {/* <div className="wave"></div> */}
      <LottieControl />

      {show && (
        <div className='modal-outer'>
          <div className='modal'>
            <div className='modal-header'>
              Contact Us
              <button onClick={() => setShow(!show)}><img src={Close} alt="Close" /></button>
            </div>
            <div className='modal-body'>
              <Form />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
